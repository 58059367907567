import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { FormTitle, FormText, FormSpacer, FormSpacerRow } from "./styled";

import Button from "components/Form/Button";
import Input from "components/Form/Input";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

import ContainerUnauthenticated from "containers/Unauthenticated";
import { DoResetPassword } from "services/authentication";
import { exposeStrapiError } from "utils";

export default function CreatePassword() {
  const history = useHistory();
  const navigate = (to) => history.push(`/${to}`);

  const params = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({});

  const institutions = [
    { label: "UEL - Universidade Estadual de Londrina", value: "UEL" },
    { label: "UENP - Universidade Estadual do Norte do Paraná", value: "UENP" },
    { label: "UEM - Universidade Estadual de Maringá", value: "UEM" },
    { label: "UNICENTRO - Universidade Estadual do Centro-Oeste", value: "UNICENTRO" },
    { label: "UNIOESTE - Universidade Estadual do Oeste do Paraná", value: "UNIOESTE" },
    { label: "UNESPAR - Universidade Estadual do Paraná", value: "UNESPAR" },
    { label: "UFPR - Universidade Federal do Paraná", value: "UFPR" },
    { label: "UEPG - Universidade Estadual de Ponta Grossa", value: "UEPG" },
  ];

  const formValue = (ref) => {
    return form?.[ref] ? form?.[ref] : "";
  };
  const changeForm = (value, ref) => {
    setForm({ ...form, [ref]: value });
  };

  const valid = (verbose = false) => {
    if (!formValue("password") || !formValue("password").length) {
      if (verbose) {
        toast.error("Preencha o campo: Nova senha");
      }
      return false;
    }

    if (!formValue("cpassword") || !formValue("cpassword").length) {
      if (verbose) {
        toast.error("Preencha o campo: Confirmar nova senha");
      }
      return false;
    }

    if (formValue("password") !== formValue("cpassword")) {
      if (verbose) {
        toast.error("Nova senha e confirmação da nova senha não são iguais");
      }
      return false;
    }

    if (!formValue("institution")) {
      if (verbose) toast.error("Selecione a instituição");
      return false;
    }

    return true;
  };

  const action = async () => {
    if (!valid(true)) {
      return;
    }
    setLoading(true);

    const result = await DoResetPassword({
      code: params.get("code"),
      password: formValue("password"),
      passwordConfirmation: formValue("cpassword"),
      institution: formValue("institution"),
    });

    setLoading(false);
    if (result && !exposeStrapiError(result)) {
      completNext();
    }
  };

  const completNext = () => {
    toast.success("Senha criada com sucesso");
    navigate("login");
  };

  return (
    <>
      <ContainerUnauthenticated>
        <FormTitle>Recuperar senha</FormTitle>
        <FormText>Digite uma nova senha</FormText>
        <Input
          id={"password"}
          placeholder="Senha"
          type="password"
          value={formValue("password")}
          onChange={(e) => changeForm(e.target.value, "password")}
        />
        <FormSpacer />
        <Input
          id={"cpassword"}
          placeholder="senha novamente"
          type="password"
          value={formValue("cpassword")}
          onChange={(e) => changeForm(e.target.value, "cpassword")}
        />
        <FormSpacer />
        <FormControl fullWidth>
          <InputLabel id="institution-select-label">Selecione a Instituição</InputLabel>
          <Select
            labelId="institution-select-label"
            id="institution-select"
            value={formValue("institution")}
            onChange={(e) => changeForm(e.target.value, "institution")}
            label="Selecione a Instituição"
          >
            {institutions.map((institution) => (
              <MenuItem key={institution.value} value={institution.value}>
                {institution.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormSpacerRow>
          <Button primary small outline onClick={() => navigate("login")}>
            Voltar
          </Button>
          <Button primary small loading={loading} onClick={action}>
            Prosseguir
          </Button>
        </FormSpacerRow>
      </ContainerUnauthenticated>
    </>
  );
}
