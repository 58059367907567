import styled from 'styled-components' 

export const FooterContainer = styled.div.attrs({ 
})`          
    background: ${ props => props.theme.palette.colors.white }; 
    padding: 12px 0;
`;


export const FooterCopyright = styled.div.attrs({  
})`          
    font-size: 20px;
    font-weight: 300;
    color: ${ props => props.theme.palette.colors.white }; 
    text-align: center;
`;

const BaseIconStyle = styled.img`
    width: 154px;
    height: 92px;
`;

export const FooterFlagUS = styled(BaseIconStyle).attrs({
    src: `/icons/us.svg`
})``;

export const FooterFlagUFPR = styled(BaseIconStyle).attrs({
    src: `/icons/ufpr.jpg`
})``;

export const FooterFlagSETI = styled(BaseIconStyle).attrs({
    src: `/icons/seti.png`
})``;

export const FooterFlagUEL = styled(BaseIconStyle).attrs({
    src: `/icons/uel.png`
})``;

export const FooterFlagUEM = styled(BaseIconStyle).attrs({
    src: `/icons/uem.png`
})``;

export const FooterFlagUENP = styled(BaseIconStyle).attrs({
    src: `/icons/uenp.png`
})``;

export const FooterFlagUEPG = styled(BaseIconStyle).attrs({
    src: `/icons/uepg.png`
})``;

export const FooterFlagUNESPAR = styled(BaseIconStyle).attrs({
    src: `/icons/unespar.png`
})``;

export const FooterFlagUNICENTRO = styled(BaseIconStyle).attrs({
    src: `/icons/unicentro.png`
})``;

export const FooterFlagUNIOESTE = styled(BaseIconStyle).attrs({
    src: `/icons/unioeste.png`
})``;
