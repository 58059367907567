import React from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
import { useHistory } from 'react-router-dom';

import styled from "styled-components";


import {
    FooterContainer,
    
    FooterFlagUS,
    FooterFlagSETI,
    FooterFlagUFPR,
    FooterFlagUEL,
    FooterFlagUEM,
    FooterFlagUENP,
    FooterFlagUEPG,
    FooterFlagUNESPAR,
    FooterFlagUNICENTRO,
    FooterFlagUNIOESTE
} from './styled' 

const StyledCol = styled(Col)`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 50px;
`;

export default function Footer({ spaced }){ 
 
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    return ( 
        <> 
            <FooterContainer>
                <Container>

                    <Row>
                        <StyledCol>
                            <FooterFlagUS /> 
                            <FooterFlagSETI />
                            <FooterFlagUFPR />
                            <FooterFlagUEL />
                            <FooterFlagUEM />
                            <FooterFlagUENP />
                            <FooterFlagUEPG />
                            <FooterFlagUNESPAR />
                            <FooterFlagUNICENTRO />
                            <FooterFlagUNIOESTE />
                        </StyledCol>
                    </Row>

                </Container>
            </FooterContainer>
        </>
    );
}